@media print {
    @page {
        margin: 1.5cm;
        margin-top: 12cm;
    }

    body * {
        background: transparent !important;
        color: black !important;
        box-shadow: none !important;
        font-size:large;
        font-family: monospace;
    }
}